<template>
  <layout :title="$route.name" :options="options">
    <div class="expande-horizontal wrap">
      <v-flex xs12>
        <div class="expande-horizontal wrap">
          <v-flex xs12 md8>
            <div class="expande-horizontal wrap">
              <!-- <v-flex class="pa-6 pr-3 pl-0" xs12>
                <img
                  @click="redirectToMenuRocket"
                  style="width: 100%; cursor: pointer;"
                  :src="require('@/apps/shared/assets/banner.png')"
                />
              </v-flex> -->
              <v-flex class="pa-6 pt-3 pl-3" xs12 md6>
                <v-card hover style="border-radius: 10px;">
                  <v-flex class="pa-6" xs12>
                    <div class="expande-horizontal mb-6">
                      <img
                        width="30"
                        height="30"
                        :src="
                          require('@/apps/shared/assets/shopping-bag-1.svg')
                        "
                      />
                    </div>
                    <div class="expande-horizontal wrap mb-6">
                      <span class="fonte">Bem Vindo Adm</span>
                    </div>
                    <div class="expande-horizontal">
                      <!-- <h2 v-if="showBalance" class="fonte">
                        {{ $helper.formataSaldo(get_dashboard.hoje) }}
                      </h2> -->
                      <h2 class="fonte">secure</h2>
                    </div>
                  </v-flex>
                </v-card>
              </v-flex>
            </div>
          </v-flex>
          <v-flex v-if="false" class="pa-6 pt-0 pl-0" xs12 md4>
            <div class="expande-horizontal wrap">
              <v-flex class="pa-3" xs12>
                <v-card hover style="border-radius: 10px;">
                  <v-flex xs12>
                    <div class="expande-horizontal wrap">
                      <v-flex xs12>
                        <div class="expande-horizontal pa-6 pb-3">
                          <span class="fonte">Saldo Geral</span>
                          <v-spacer></v-spacer>
                          <div
                            class="icon-show-balance"
                            @click="showBalance = !showBalance"
                            color="grey lighten-2"
                          >
                            <v-icon v-if="showBalance">mdi-eye-outline</v-icon>
                            <v-icon v-if="!showBalance"
                              >mdi-eye-off-outline</v-icon
                            >
                          </div>
                        </div>

                        <div class="expande-horizontal pa-6 pt-0">
                          <h2 v-if="showBalance" class="fonte green--text">
                            {{ $helper.formataSaldo(get_dashboard.total) }}
                          </h2>
                          <h2 v-if="!showBalance" class="fonte green--text">
                            R$ *****
                          </h2>
                        </div>

                        <div class="expande-horizontal pa-6 pt-0">
                          <div class="divider-balance" />
                        </div>

                        <div class="expande-horizontal pa-6 pb-0 pt-0">
                          <span class="fonte">Formas de Pagamento</span>
                        </div>
                        <div class="expande-horizontal pa-6 pb-0 pt-0">
                          <v-flex xs12>
                            <MyChartRadial
                              class="fonte"
                              type="pie"
                              height="200"
                              :labels="labelsPayments"
                              id="Pagamentos"
                              :data1="dataPayments"
                            />
                          </v-flex>
                        </div>

                        <!-- <div class="expande-horizontal pa-6 pt-0">
                          <div class="divider-balance" />
                        </div>

                        <div class="expande-horizontal pa-6 pb-0 pt-0">
                          <span class="fonte">Top Produtos</span>
                          <v-spacer></v-spacer>
                          <v-icon>mdi-trophy-variant-outline</v-icon>
                        </div>

                        <div class="expande-horizontal pa-6 pb-0 pt-0">
                          <v-flex xs12>
                            vlist
                          </v-flex>
                        </div> -->
                      </v-flex>
                    </div>
                  </v-flex>
                </v-card>
              </v-flex>
            </div>
          </v-flex>
        </div>
      </v-flex>
    </div>
  </layout>
</template>

<script>
import Notificacoes from "./components/Notificacoes";
import Link from "./components/Link";
import ListagemDeAlunos from "./components/ListagemDeAlunos";
import CalendarioAcademico from "./components/CalendarioAcademico";
import Matriculas from "./components/Matriculas";
import RequerimentosEmAberto from "./components/RequerimentosEmAberto";
import Cora from "./components/Cora.vue";
import MyChart from "./components/baseChart";
import MyChartRadial from "./components/baseChartCircle";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    MyChart,
    MyChartRadial,
    Notificacoes,
    Link,
    Cora,
    ListagemDeAlunos,
    CalendarioAcademico,
    Matriculas,
    RequerimentosEmAberto
  },
  data() {
    return {
      showBalance: true,
      skill: 30,
      payload: {
        periodo: "Últimos 7 dias"
      },
      labelsPayments: ["Dinheiro", "Débito", "Crédito", "Pix"],
      labels: ["1", "2", "3", "4", "5", "6", "7"],
      data1: ["2", "4", "8", "3", "9"],
      data2: [200, 30, 200, 70, 10, 40, 90],
      dataPayments: [200, 30, 200, 70]
    };
  },
  computed: {
    ...mapGetters(["get_dashboard"])
  },
  methods: {
    ...mapActions(["listar_dashboard"]),
    redirectToMenuRocket() {
      window.open("https://menurocket.com.br", "_blank");
    }
  },
  created() {
    // this.listar_dashboard();
  }
};
</script>

<style>
.fonte-color {
  color: #075059;
}
.icon-show-balance {
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px dashed #000;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.divider-balance {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #000;
}
</style>
