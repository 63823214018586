<template>
  <v-card
    height="120"
x    class="expande-horizontal pa-3"
    style="flex-wrap: wrap;"
  >
    <div
      class="expande-horizontal"
      style="flex-wrap: nowrap; align-items: center;"
    >
      <div class="coluna">
        <h4 class="fonte-color fonte-subtitulo">Link da minha loja</h4>
      </div>
      <v-spacer></v-spacer>
    </div>
    <div
      class="expande-horizontal"
      style="flex-wrap: nowrap; align-items: center;"
    >
      <div style="width: 100%" class="expande-horizontal mb-5 pb-1 pt-0">
        <input
          id="foo"
          class="fonte-subtitulo"
          :value="myLink"
          style="color: #075059; width: 100%; border: 1px solid #075059; border-radius: 5px; padding: 5px; height: 40px; margin-right: 6px;"
        />
        <button
          class="btn1"
          @click="
            createGlobalMessage({
              type: 'success',
              message: 'Link copiado com sucesso!',
              timeout: 3000
            })
          "
          data-clipboard-target="#foo"
          style="background-color: #e5e5e5;border-radius: 6px;width: 40px; height: 40px;"
        >
          <v-icon color="#075059">mdi-content-save</v-icon>
        </button>
      </div>
      <!-- <v-icon>mdi-cash-multiple</v-icon> -->
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ClipboardJS from "clipboard";

export default {
  data() {
    return {
      myLink: ""
    };
  },
  computed: {
    ...mapGetters(["getLoggedUser"])
  },
  methods: {
    ...mapActions(["createGlobalMessage"]),
    linkMount() {
      this.myLink = `https://catalogo.stonepro.com.br/#/loja/${this.getLoggedUser.activeTenant}`;
      new ClipboardJS(".btn1");
    }
  },
  mounted() {
    this.linkMount();
  }
};
</script>
