<template>
  <canvas :height="height" :id="this.id"></canvas>
</template>

<script>
import Chart from "chart.js";
export default {
  props: ["id", "id2", "type", "labels", "data1", "data2", "height"],
  data() {
    return {};
  },
  watch: {
    dados() {
      this.montaChart();
    }
  },
  methods: {
    montaChart() {
      var tela = document.getElementById(this.id).getContext("2d");
      var chart = new Chart(tela, {
        // The type of chart we want to create
        type: this.type,

        // The data for our dataset
        data: {
          labels: this.labels,
          datasets: [
            {
              label: this.id,
              backgroundColor: "#6B9499",
              borderColor: [
                "rgba(2, 200, 37, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)"
              ],
              data: this.data1, // data: this.dados
              fontColor: "rgba(255,255,255,1)",
              color: "rgba(255,255,255,1)"
            },
            {
              label: this.id2,
              backgroundColor: ["rgba(60, 172, 186, 0.2)"],
              borderColor: ["#0BC1D9"],
              data: this.data2, // data: this.dados
              fontColor: "rgba(255,255,255,1)",
              color: "rgba(255,255,255,1)"
            }
          ]
        },

        // Configuration options go here
        options: {}
      });
    }
  },
  mounted() {
    this.montaChart();
  }
};
</script>
