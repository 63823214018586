<template>
  <v-card
    @click="$router.push('/requerimentos')"
    dark
    height="120"
    class="expande-horizontal bg-card-dash pa-3"
    style="flex-wrap: wrap;"
  >
    <div
      class="expande-horizontal"
      style="flex-wrap: nowrap; align-items: center;"
    >
      <span :style="`font-size: 18px; font-weight: bold; color: white`"
        >REQUERIMENTOS EM ABERTO</span
      >
      <v-spacer></v-spacer>
      <!-- <v-icon>mdi-cash-multiple</v-icon> -->
    </div>

    <div
      class="expande-horizontal"
      style="flex-wrap: nowrap; align-items: center;"
    >
      <div class="coluna">
        <!-- <span style="font-size: 8pt;" class="fonte"> Total em Vendas </span> -->
        <span class="font-weight-light white--text"
          >{{ get_requerimentos.docs.length }} Solicitações</span
        >
      </div>
      <v-spacer></v-spacer>
      <div>
        <v-icon color="white"> mdi-clipboard-text-multiple-outline </v-icon>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["get_requerimentos", "get_requerimentos_filtros"])
  },
  methods: {
    ...mapActions(["listar_requerimentos"])
  },
  created() {
    this.get_requerimentos_filtros.status = "PENDENTE";
    this.listar_requerimentos();
  }
};
</script>
