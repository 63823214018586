<template>
  <v-card
    @click="$router.push('/notifications')"
    dark
    height="120"
    class="expande-horizontal bg-card-dash pa-3"
    style="flex-wrap: wrap;"
  >
    <div
      class="expande-horizontal"
      style="flex-wrap: nowrap; align-items: center;"
    >
      <span :style="`font-size: 18px; font-weight: bold; color: white`">{{
        getNotifications.received.length
      }}</span>
      <v-spacer></v-spacer>
      <!-- <v-icon>mdi-cash-multiple</v-icon> -->
    </div>

    <div
      class="expande-horizontal"
      style="flex-wrap: nowrap; align-items: center;"
    >
      <div class="coluna">
        <!-- <span style="font-size: 8pt;" class="fonte"> Total em Vendas </span> -->
        <span class="fonteTitulo white--text">NOTIFICAÇÕES</span>
      </div>
      <v-spacer></v-spacer>
      <div>
        <v-icon color="white"> mdi-bell-outline </v-icon>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getNotifications"])
  }
};
</script>
